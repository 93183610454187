import React from "react";
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Stack,
  TextInput,
  Tooltip,
  Text,
  ScrollArea,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FormType, StepProps } from "./@Types";
import CrossIcon from "./assets/CrossIcon";
import { hasDuplicates, nanoid } from "./Utils";

const SecondStep = ({ form, platform, setPlatform }: StepProps) => {
  const errorHandel = (form: UseFormReturnType<FormType>, index: number) => {
    if (form.values.articleUrl[index].url === "") {
      return "* This can not be empty!";
    }
    if (hasDuplicates(form.values.articleUrl)) {
      return "* Duplicate";
    }
    return "";
  };

  const fields = form.values.articleUrl.map((item, index) => {
    item.error = errorHandel(form, index);
    return (
      <Group key={item.key}>
        <TextInput
          pb={"md"}
          placeholder="https://www.vg.no/annonsorinnhold/.../test/a/Udhsj"
          withAsterisk
          sx={{ flex: 1 }}
          {...form.getInputProps(`articleUrl.${index}.url`)}
          error={form.values.articleUrl[index].error}
        />
        <Tooltip label="Remove">
          <ActionIcon onClick={() => form.removeListItem("articleUrl", index)}>
            <CrossIcon />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  });

  return (
    <Stack mx={"4rem"}>
      <Checkbox.Group
        value={platform}
        onChange={setPlatform}
        label="Select platforms (whitch reports should be made)"
        withAsterisk
        mb={"md"}
      >
        <Checkbox value="vg" label="VG" />
        <Checkbox value="e24" label="E24" />
        <Checkbox value="storby" label="Storby" />
      </Checkbox.Group>
      {fields.length > 0 ? null : (
        <Text color="dimmed" align="center">
          You need at last one url to create a report
        </Text>
      )}
      <ScrollArea style={{ height: 250 }} pr="md">
        {fields}
        <Group position="center" mt="md">
          <Button
            onClick={() =>
              form.insertListItem("articleUrl", {
                url: "",
                key: nanoid(),
              })
            }
          >
            Add article url
          </Button>
        </Group>
      </ScrollArea>
    </Stack>
  );
};

export default SecondStep;
