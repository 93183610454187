import React, { useState } from "react";
import {
  ActionIcon,
  Group,
  Modal,
  Stack,
  TextInput,
  Image,
  Text,
  Tooltip,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { StepProps } from "./@Types";

import HelpIcon from "./assets/HelpIcon";
import HelpImage from "./assets/HelpLogo.png";

const FirstStep = ({ form }: StepProps) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Modal opened={opened} onClose={() => setOpened(false)}>
        <Text p={"lg"}>
          Go to you content article, right click and copy the image url from the
          top header section.
        </Text>
        <Image p="lg" src={HelpImage} />
      </Modal>
      <Stack mx={"4em"}>
        <TextInput
          pb={"lg"}
          label="Customer"
          placeholder="Customer name"
          {...form.getInputProps("kunde")}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextInput
            pb={"lg"}
            mr="sm"
            label="Customer logo"
            placeholder="Url to customer logo"
            {...form.getInputProps("logo")}
          />
          <Tooltip label="Help">
            <ActionIcon onClick={() => setOpened(true)}>
              <HelpIcon />
            </ActionIcon>
          </Tooltip>
        </div>
        <TextInput
          pb={"lg"}
          label="Article or campain title"
          placeholder="Title"
          {...form.getInputProps("artikkelTittel")}
        />
        <Group mt={"1rem"} mb={"2rem"}>
          <DatePicker
            placeholder="Start date"
            label="Campain start date"
            inputFormat="YYYY-MM-DD"
            {...form.getInputProps("fra")}
          />
          <DatePicker
            placeholder="End date"
            label="Campain end date"
            inputFormat="YYYY-MM-DD"
            {...form.getInputProps("til")}
          />
        </Group>
      </Stack>
    </>
  );
};

export default FirstStep;
