import {
  ActionIcon,
  Checkbox,
  Modal,
  Stack,
  TextInput,
  Tooltip,
  Text,
  Image,
  Header,
  List,
} from "@mantine/core";
import React, { useState } from "react";
import { StepProps } from "./@Types";

import HelpIcon from "./assets/HelpIcon";
import RoseNumber from "./assets/RoseNumber.png";
import VantageURL from "./assets/VantageURL.png";

const LastStep = ({ form, campains, setCampains }: StepProps) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Modal opened={opened} onClose={() => setOpened(false)}>
        <List type="ordered" p={"lg"}>
          <List.Item>
            Go to Vantage, and use the top right search bar to search for the
            Rose number. Click on the correct campaign.
            <Image p="lg" src={RoseNumber} />
          </List.Item>
          <List.Item>
            Copy the Vantage url from the browser. The url should be on the
            format: http://vantage.schibsted.io/promoters/...
            <Image p="lg" src={VantageURL} />
          </List.Item>
        </List>
      </Modal>
      <Stack mx={"4rem"}>
        <Checkbox.Group
          value={campains}
          onChange={setCampains}
          label="Select report type to create"
          withAsterisk
          mb={"md"}
        >
          <Checkbox value="cpm" label="CPM" />
          <Checkbox value="sov" label="SOV" />
          <Checkbox value="e24-pakke" label="E24 UKES-/MÅNEDSPAKKE" />
        </Checkbox.Group>
        {campains.includes("cpm") ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextInput
              pb={"lg"}
              mr="sm"
              label="Vantage"
              placeholder="Vantage url"
              {...form.getInputProps("vantage")}
            />
            <Tooltip label="Help">
              <ActionIcon onClick={() => setOpened(true)}>
                <HelpIcon />
              </ActionIcon>
            </Tooltip>
          </div>
        ) : null}
        <TextInput
          pb={"lg"}
          label="email"
          placeholder="Schibsted email"
          {...form.getInputProps("mail")}
        />
      </Stack>
    </>
  );
};

export default LastStep;
