import React from "react";

export default function CrossIcon() {
  return (
    <svg
      viewBox="0 0 48 48"
      width={20}
      height={20}
      xmlns="http://www.w3.org/2000/svg"
      fill="#868687"
    >
      <path d="M12.45 37.65l-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1z" />
    </svg>
  );
}
