import React from "react";
import FormStepper from "./components/Stepper";
import { Card, Group, Stack, Text } from "@mantine/core";

import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Stack
        align="center"
        justify="center"
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          height: "100vh",
        })}
      >
        {/* <h1>Order article report</h1> */}
        <FormStepper />
      </Stack>
    </div>
  );
};

export default App;
