import React, { useState } from "react";
import { Stepper, Button, Group, Code } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  compareDate,
  hasDuplicates,
  hasEmptyUrl,
  isUrl,
  isVantageUrl,
  multipleExist,
  nanoid,
  objValueToArray,
  OnlyIncludesContentUrl,
  showError,
} from "./Utils";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import LastStep from "./LastStep";
import Complete from "./Complete";

const FormStepper = () => {
  const [active, setActive] = useState(0);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [campains, setCampains] = useState<string[]>([]);

  const form = useForm({
    initialValues: {
      kunde: "",
      flate: platforms,
      reportType: campains,
      fra: "",
      til: "",
      logo: "",
      artikkelTittel: "",
      vantage: "",
      mail: "",
      articleUrl: [{ url: "", key: nanoid(), error: "" }],
    },
    validate: {
      kunde: (value) =>
        value.length < 2 ? "* Customer name can't be empty!" : null,
      fra: (value) => (value ? null : "* Missing start date"),
      til: (value) => (value ? null : "* Missing end date"),
      logo: (value) => (isUrl.test(value) ? null : "* Use a valid URL"),
      flate: () =>
        platforms.length > 0 ? null : "* Must choose one or more plattforms",
      reportType: () =>
        campains.length > 0 ? null : "* Must choose one or more report type",
      artikkelTittel: (value) =>
        value.length < 1 ? "* Article title can't be empty!" : null,
      vantage: (value) =>
        isVantageUrl(value) || !campains.includes("cpm")
          ? null
          : "* Must be a valid Vantage url",
      mail: (value) =>
        /[A-Za-z0-9._%+-]+@schibsted+\.[a-z]{2,3}/.test(value)
          ? null
          : "* This is not a valid Schibsted mail!",
      articleUrl: (value) =>
        !hasDuplicates(value) &&
        !hasEmptyUrl(value) &&
        OnlyIncludesContentUrl(value)
          ? null
          : "* Invalid url",
    },
  });

  const validateCurrentStep = (
    arr: string[],
    step: number,
    current: number
  ): boolean => {
    const found = arr.some((r) =>
      Object.keys(form.validate().errors).includes(r)
    );
    return found && current == step;
  };

  const nextStep = () => {
    setActive((current) => {
      if (
        validateCurrentStep(
          ["kunde", "fra", "til", "logo", "artikkelTittel"],
          0,
          current
        ) ||
        !compareDate(form.values.fra, form.values.til)
      ) {
        showError(
          !compareDate(form.values.fra, form.values.til),
          "Campain end-date must be later than start-date and not equal!",
          "dateError"
        );
        return current;
      }
      const urls = objValueToArray(form.values.articleUrl);
      if (
        validateCurrentStep(["flate", "articleUrl"], 1, current) &&
        !multipleExist(urls, platforms)
      ) {
        if (
          platforms.includes("vg") ||
          platforms.includes("e24") ||
          platforms.includes("storby")
        ) {
          showError(
            !multipleExist(urls, ["vg", "e24", "storby"]),
            "Can't create report for platform. Please add one or more URLs for each selected platforms.",
            "platformErrorVG",
            "Error: Missing URLs"
          );
        }
        return current;
      }
      if (validateCurrentStep(["vantage", "mail"], 2, current)) {
        return current;
      }
      return current < 3 ? current + 1 : current;
    });
  };

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <div style={{ width: "650px" }}>
      <Stepper active={active} breakpoint="sm">
        <Stepper.Step label="First step" description="Article info" m={"lg"}>
          <FirstStep form={form} />
        </Stepper.Step>

        <Stepper.Step label="Second step" description="Campain info">
          <SecondStep
            form={form}
            platform={platforms}
            setPlatform={setPlatforms}
          />
        </Stepper.Step>

        <Stepper.Step label="Final step" description="Order">
          <LastStep form={form} setCampains={setCampains} campains={campains} />
        </Stepper.Step>

        <Stepper.Completed>
          <Complete form={form} platforms={platforms} campains={campains} />
        </Stepper.Completed>
      </Stepper>

      <Group position="right" mt="xl">
        {active !== 0 && (
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
        )}
        {active !== 3 && <Button onClick={nextStep}>Next step</Button>}
      </Group>
    </div>
  );
};

export default FormStepper;
