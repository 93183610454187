import React, { useState } from "react";
import { Accordion, Button, Code, Group, Stack, Text } from "@mantine/core";
import { StepProps } from "./@Types";
import { convertUrlsToIDs, dateFormat, filterUrls } from "./Utils";

type Props = StepProps & {
  platforms: string[];
};

const Complete = ({ form, platforms, campains }: Props) => {
  const [value, setValue] = useState<string | null>(null);
  const [clicked, setClick] = useState<boolean>(false);

  const body: string = JSON.stringify(
    {
      tekst: {
        kunde: form.values.kunde,
        flate: platforms,
        fra: dateFormat(form.values.fra),
        til: dateFormat(form.values.til),
        logo: form.values.logo,
        artikkelTittel: form.values.artikkelTittel,
      },
      vantage: form.values.vantage,
      mail: form.values.mail,
      articleUrl: filterUrls(form.values.articleUrl),
      reportType: campains,
    },
    null,
    2
  );

  const handleOrder = () => {
    setClick(true);
    fetch("/reports", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Stack mx={"4em"}>
        {!clicked ? (
          <>
            <Text>
              By clicking the button <b>ORDER REPORT</b> a report will be
              created based on the information provided (check below if
              necessary) and deliverd to <b>{form.values.mail}</b> when it is
              finished.
            </Text>
            <Button
              onClick={() => handleOrder()}
              size="md"
              variant="light"
              disabled={clicked}
            >
              ORDER REPORT
            </Button>
            <Accordion defaultValue="data" value={value} onChange={setValue}>
              <Accordion.Item value="data">
                <Accordion.Control>
                  See all answers before create the report
                </Accordion.Control>
                <Accordion.Panel>
                  <Code block mt="xl">
                    {body}
                  </Code>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </>
        ) : (
          <Group position="center">
            <Text>The report is being created...</Text>
            <Text>
              Check <b>{form.values.mail}</b> to se the result
            </Text>
          </Group>
        )}
      </Stack>
    </>
  );
};

export default Complete;
