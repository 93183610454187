import { ArticleInfo } from "./@Types";
import { showNotification } from "@mantine/notifications";

export const isUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const isVantageUrl = (url: string) => {
  const pattern = new RegExp(
    "^[(http(s)?)://(www.)(vantage.schibsted)]{2,256}.(io)/\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$"
  );
  return pattern.test(url);
};

export const dateFormat = (dateStr: string): string => {
  const date: string = new Date(dateStr).toLocaleDateString("no-NO", {
    dateStyle: "short",
  });
  const dateArr = date.split(".");
  return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
};

export const compareDate = (startDate: string, endDate: string): boolean => {
  return new Date(endDate) > new Date(startDate);
};

export const isContentUrl = (url: string): boolean => {
  const pattern = new RegExp(
    "^[(http(s)?)://(www.)(vg|aftenposten|e24|adressa|bt|fvn|aftenbladet)]{2,256}.(no)/(annonsorinnhold|brandstudio|brandStudio)/\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$"
  );
  return pattern.test(url);
};

export const isCreateUrl = (url: string | URL) => {
  const urlParams = new URL(url).pathname.split("/").slice(1, -1);
  if (
    /\b[a-z]{1}\b/.test(urlParams[urlParams.length - 2]) &&
    /\b[a-zA-z0-9]{6}\b/.test(urlParams[urlParams.length - 1])
  ) {
    return urlParams[urlParams.length - 1];
  }
};

// Rewrite brandStudio to brandstudio
export const rewrite = (url: any) => {
  if (url.includes("brandStudio")) {
    return url.replace("brandStudio", "brandstudio");
  } else {
    return url;
  }
};

export const getContentId = (path: string) => {
  if (isContentUrl(path) && path) {
    if (!path.endsWith("/")) {
      path = `${path}/`;
    }
    let url = new URL(path);
    let encode;
    if (isCreateUrl(url) !== undefined) {
      encode = isCreateUrl(url);
    } else {
      encode = encodeURIComponent(url.pathname.slice(1, -1));
    }
    let contentId = rewrite(encode);
    return `%27${contentId}%27`;
  }
};

export const displayInputError = (url: string) => {
  return {
    error: isContentUrl(url) || url === "",
    errorMessage: "Ugyldig content url",
  };
};

export const hasDuplicates = (array: any) => {
  let result = array.map((obj: any) => obj.url);
  if (result !== "") {
    return new Set(result).size !== result.length;
  }
};

export const displayDuplicatesError = (array: any) => {
  return {
    error: !hasDuplicates(array),
    errorMessage: "Duplikat",
  };
};

export const hasEmptyUrl = (array: any) => {
  let isEmpty = false;
  array.map((x: any) => (isEmpty = x.url === ""));
  return isEmpty;
};

export const OnlyIncludesContentUrl = (array: ArticleInfo[]) => {
  let isContent = false;
  array.map((x: ArticleInfo) => (isContent = isContentUrl(x.url)));
  return isContent;
};

export const multipleExist = (
  arr: Array<string | number | URL>,
  values: Array<string | number | URL>
): boolean => {
  return values.every((value: string | number | URL) => {
    console.log(arr, values, arr.includes(value));
    return arr.includes(value);
  });
};

export const objValueToArray = (arr: ArticleInfo[]) => {
  return arr.map((item: ArticleInfo) => {
    return item.url;
  });
};

export const convertUrlsToIDs = (array: ArticleInfo[]) => {
  const arr = array.map((obj: ArticleInfo) => {
    if (obj.url !== "") {
      return getContentId(obj.url);
    }
  });
  return arr;
};

export const filterUrls = (array: ArticleInfo[]) => {
  const arr = array.map((obj: ArticleInfo) => {
    if (obj.url !== "") {
      return obj.url;
    }
  });
  return arr;
};

export const showError = (
  condition: boolean,
  errorMsg: string,
  id: string,
  title?: string
) => {
  if (condition) {
    showNotification({
      id: id,
      title: title ? title : "Error",
      message: errorMsg,
      color: "red",
      autoClose: 10000,
    });
  }
};

export let nanoid = (t: number = 21): string =>
  crypto
    .getRandomValues(new Uint8Array(t))
    .reduce(
      (t, e) =>
        (t +=
          (e &= 63) < 36
            ? e.toString(36)
            : e < 62
            ? (e - 26).toString(36).toUpperCase()
            : e > 62
            ? "-"
            : "_"),
      ""
    );
